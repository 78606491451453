import { SetStateAction, useEffect, useState, useRef  } from "react";
import { Storage } from 'aws-amplify';
import aws_config from '../../modified_aws-export';
import { useConfigState, useI18nState } from "../../store";
import { pineconeQuery } from "../../api/backend";
import { DropFile } from "../DropZone/DropZone";
import { useFilterState } from "../../store/";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CreatableSelect from 'react-select/creatable';
import { useRecordEvent } from 'aws-rum-react';


interface FilterProps {
    setResults?: SetStateAction<any>;
    dropfile: DropFile
}

const MetadataFilter: React.FC<FilterProps> = ({ setResults, dropfile }) => {
    const { selectedLanguage } = useI18nState();
    const { t } = useTranslation();
    const [itemGroups, setItemGroups] = useState<any>([{ value: '', label: t("DropZone.loading") }])
    const [manufacturers, setManufactures] = useState<any>([{ value: '', label: t("DropZone.loading") }])
    const { top_k } = useConfigState()
    const { itemGroupFilter, setItemGroupFilter, manufacturerFilter, setManufacturerFilter, namespace, setNamespace } = useFilterState()
    const [previousNamespace, setPreviousNamespace] = useState("")
    const [kindOfFilter, setKindOfFilter] = useState("")
    const [bothIsChecked, setBothIsChecked] = useState(true)
    const [singleIsChecked, setSingleIsChecked] = useState(false)
    const [oemIsChecked, setOemIsChecked] = useState(false)
    const [aimIsChecked, setAimIsChecked] = useState(false)
    const [filterDisabled, setFilterDisabled] = useState(false)
    const { setShowDeepSearchHint, showDeepSearchHint } = useConfigState()
    const recordEvent = useRecordEvent();
    const subscriptionRef = useRef<any>(null);


    useEffect(() => {
        setBothIsChecked(false);
        setSingleIsChecked(false);
        setOemIsChecked(false);
        setAimIsChecked(false)
        switch (namespace) {
            case "":
                setBothIsChecked(true);
                break;
            case "Single":
                setSingleIsChecked(true);
                break;
            case "OEM":
                setOemIsChecked(true);
                break;
            case "AimModel":
                setAimIsChecked(true);
                break;
        }
    }, [namespace])

    useEffect(() => {
        Storage.configure({
            aws_user_files_s3_bucket_region: aws_config.backend.region, // (required) - Amazon S3 bucket region
            aws_user_files_s3_bucket: aws_config.backend.bucket_name // (required) - Amazon S3 bucket name
        })
        var config = {
            level: 'public',
            customPrefix: { public: '' }
        };

        const t = async (config: any) => {
            try {
                let itemGoupsUrl = await Storage.get(`REFERENCEDATA/item_groups.json`, { ...config, validateObjectExistence: true });
                const responseItemGroups = await fetch(itemGoupsUrl).then((res => res.json()))
                let desiredListItemGroups = [];
                for (let key of Object.keys(responseItemGroups[selectedLanguage])) {
                    let itemText = responseItemGroups[selectedLanguage][key].ItemText;

                    let desired = {
                        value: key,
                        label: key.replace("-", "") + " - " + itemText,
                    };

                    desiredListItemGroups.push(desired);
                }
                setItemGroups(desiredListItemGroups)

                let desiredListManufacturers = [];
                let manufacturerListUrl = await Storage.get(`REFERENCEDATA/manufacturers.json`, { ...config, validateObjectExistence: true });
                const responseManufacturers = await fetch(manufacturerListUrl).then((res => res.json()))

                for (let key of Object.keys(responseManufacturers)) {
                    let desired = {
                        value: key,
                        label: responseManufacturers[key] + " (" + key + ")"
                    };
                    desiredListManufacturers.push(desired);
                }
                setManufactures(desiredListManufacturers)


            } catch (error) {
                console.log(error)
            }
        }
        t(config)
    }, [selectedLanguage])

    const check = async (selectedManufacturers: any, selectedItemGroups: any, namespace: any) => {
        let choice_filter: any = []
        let namespaceFilter = {}
        if (namespace !== "" && namespace !== "AimModel") {
            namespaceFilter = { "namespace": namespace }
        }
        else (
            namespaceFilter = { "namespace": {"$in":["OEM","Single"]}}
        )
        
        if (selectedManufacturers.length !== 0 && selectedItemGroups.length !== 0) {
            for (let itemGroup of selectedItemGroups) {
                const str = itemGroup["value"]
                const splitted = [str.slice(0, 2), str.slice(2)]
                for (let manufacturer of selectedManufacturers) {
                    choice_filter.push({ "$and": [{ "SubGroupID": splitted[0] }, { "ItemGraphicID": splitted[1] }, { "Manufacturer": manufacturer["value"] }, namespaceFilter] })
                }
            }
        }
        else if (selectedManufacturers.length === 0 && selectedItemGroups.length !== 0) {
            for (let itemGroup of selectedItemGroups) {
                const str = itemGroup["value"]
                const splitted = [str.slice(0, 2), str.slice(2)]
                choice_filter.push({ "$and": [{ "SubGroupID": splitted[0] }, { "ItemGraphicID": splitted[1] }, namespaceFilter] })
            }
        }
        else if (selectedManufacturers.length !== 0 && selectedItemGroups.length === 0) {
            for (let manufacturer of selectedManufacturers) {
                choice_filter.push({ "$and": [{ "Manufacturer": manufacturer["value"] }, namespaceFilter] })
            }
        }
        else {
            choice_filter.push({ "$and": [namespaceFilter] })
        }
        let test = {
            "$or": choice_filter
        };

        setFilterDisabled(true)
        if (namespace === "AimModel"){
/*             (async () => { */
subscriptionRef.current = await pineconeQuery(dropfile, aws_config.aws_user_files_s3_bucket, dropfile.id + ".png", Number(top_k), setResults, recordEvent, JSON.stringify(test), setFilterDisabled, "aim-600M-2B-imgs", "TecRMIAIMModel")
/*                 })(); */
        }
        else {
/*             (async () => { */
                pineconeQuery(dropfile, aws_config.aws_user_files_s3_bucket, dropfile.id + ".png", Number(top_k), setResults, recordEvent, JSON.stringify(test), setFilterDisabled, "vit_small_patch16_224")

/*                 })(); */
        }
    }

    const changeFilter = (choice: any, key: any) => {
        if (key === "Item") {
            setItemGroupFilter(JSON.stringify(choice))
            setKindOfFilter("Item")
            check(JSON.parse(manufacturerFilter), choice, namespace)
        }
        else if (key === "Manufacturers") {
            setManufacturerFilter(JSON.stringify(choice))
            setKindOfFilter("Manufacturers")
            check(choice, JSON.parse(itemGroupFilter), namespace)
        }
        else if (key === "Namespace") {
            setPreviousNamespace(namespace)
            setNamespace(choice)
            setKindOfFilter("Namespace")
            check(JSON.parse(manufacturerFilter), JSON.parse(itemGroupFilter), choice)
        }
    }

    const changeForm = (formFilter: any) => {
        switch (formFilter.target.id) {
            case "":
                recordEvent('SetAllImagesFilter', {'description':'User selected all images in the filter possibility'});
                break;
            case "Single":
                recordEvent('SetSingleImagesFilter', {'description':'User selected single images in the filter possibility'});
                break;
            case "OEM":
                recordEvent('SetPairsImagesFilter', {'description':'User selected pair images in the filter possibility'});
                break;
            case "AimModel":
                if (showDeepSearchHint === false) {
                    if (window.confirm(t("Filter.aimModelConfirmation"))) {
                        recordEvent('SetAimModelFilter', {'description':'User selected AimModel in the filter possibility and confirmed confirmation box'});
                        //changeFilter(formFilter.target.id, "Namespace")
                    } else {
                        recordEvent('SetAimModelFilterButAborted', {'description':'User selected AimModel in the filter possibility, but aborted confirmation box'} );
                        setPreviousNamespace(namespace)
                        setNamespace(namespace);
                        return;
                    }
                } else {
                    recordEvent('SetAimModelFilter', {'description':'User selected AimModel in the filter possibility and confirmed confirmation box'});
                }
                break;
        }
        changeFilter(formFilter.target.id, "Namespace")
    }

    const cancelFilter = () => {
        setNamespace(previousNamespace);
        // window.location.reload()
        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
            setFilterDisabled(false)
        }
    }

    return (
        <div className='card mb-1 border-0 d-md-block' style={{ width: "65%", margin: "auto" }}>
            <div className="card" style={{ padding: "5px", margin: "5px" }}>
                <Form>
                    <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                            inline
                            label={t("Filter.oemAndSingle")}
                            name="namespace"
                            type="radio"
                            id={``}
/*                             defaultChecked={bothIsChecked} */
                            disabled={filterDisabled}
                            checked = {bothIsChecked}
                            onChange={changeForm}
                        />
                        <Form.Check
                            inline
/*                             defaultChecked={oemIsChecked} */
                            label={t("Filter.onlyOEM")}
                            name="namespace"
                            type="radio"
                            id={`OEM`}
                            disabled={filterDisabled}
                            checked = {oemIsChecked}
                            onChange={changeForm}
                        />
                        <Form.Check
                            inline
/*                             defaultChecked={singleIsChecked} */
                            label={t("Filter.onlySingle")}
                            name="namespace"
                            type="radio"
                            id={`Single`}
                            disabled={filterDisabled}
                            checked = {singleIsChecked}
                            onChange={changeForm}
                        />
                        <Form.Check
                            inline
/*                             defaultChecked={aimIsChecked} */
                            label={t("Filter.aimModel")}
                            name="namespace"
                            type="radio"
                            id={`AimModel`}
                            disabled={filterDisabled}
                            checked = {aimIsChecked}
                            onChange={changeForm}
                            /* onMouseOver={mouseover} */
                        />
                        
                        {filterDisabled ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {kindOfFilter === "Namespace" && namespace === "AimModel" ? 
                                    <button type="button" className="btn-close btn-sm" aria-label="Close" title={t("Filter.calculationCancellation")} onClick={(event: any) => cancelFilter()}></button>
                                    :
                                    <></>
                                }
                                
                            </>
                        : <></>
                        } 
                    </div>
                </Form>

                <CreatableSelect
                    className='card border-0 d-md-block'
                    closeMenuOnSelect={false}
                    isMulti
                    options={itemGroups}
                    defaultValue={JSON.parse(itemGroupFilter)}
                    placeholder={t("Filter.itemgroup")}
                    isSearchable
                    onChange={(choice) => changeFilter(choice, "Item")}
                    isDisabled = {filterDisabled}
                />

                <CreatableSelect
                    className='card border-0 d-md-block'
                    closeMenuOnSelect={false}
                    isMulti
                    options={manufacturers}
                    defaultValue={JSON.parse(manufacturerFilter)}
                    placeholder={t("Filter.manufacturer")}
                    isSearchable
                    onChange={(choice) => changeFilter(choice, "Manufacturers")}
                    isDisabled = {filterDisabled}
                />
            </div>
        </div>
    );
}

export default MetadataFilter